export default class Validate {
    // 验证用户名
    static isvalidUsername(str) {
        const valid_map = ['admin', 'editor']
        return valid_map.indexOf(str.trim()) >= 0
    }
    // 验证手机号
    static isvalidPhone(str) {
        const phoneregex = /(?:^1[3456789]|^9[28])\d{9}$/
        return phoneregex.test(str.trim())
    }
    /* 合法uri*/
    static validateURL(textval) {
        const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
        return urlregex.test(textval)
    }
    /* 小写字母*/
    static validateLowerCase(str) {
        const reg = /^[a-z]+$/
        return reg.test(str)
    }
    /* 大写字母*/
    static validateUpperCase(str) {
        const reg = /^[A-Z]+$/
        return reg.test(str)
    }
    /* 大小写字母*/
    static validatAlphabets(str) {
        const reg = /^[A-Za-z]+$/
        return reg.test(str)
    }
    /* 身份证*/
    static validatIdCard(str) {
        const reg=/^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        return reg.test(str)
    }
}