<template>

    <div class="withdrawal-content">
        <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="150px">
            <el-form-item label="企业对公账户" prop="accountNumber" v-if="form.isPrivateAccount == 1">
                <el-input style="width:400px;" v-model="form.accountNumber"
                    @input="form.accountNumber = form.accountNumber.replace(/[^\w\/]/ig, '')" placeholder="请输入">
                </el-input>
            </el-form-item>
            <el-form-item label="银行卡号" prop="accountPersonNumber" v-else>
                <el-input style="width:400px;" v-model="form.accountPersonNumber"
                    @input="form.accountPersonNumber = form.accountPersonNumber.replace(/[^\w\/]/ig, '')"
                    placeholder="请输入">
                </el-input>
            </el-form-item>
            <el-form-item label="银行账户名" prop="accountName">
                <el-input style="width:400px;" v-model="form.accountName" placeholder="请输入">
                </el-input>
            </el-form-item>
            <el-form-item label="开户银行名称" prop="bankCode">
                <el-select v-model="form.bankCode" placeholder="请选择" @change="bankChange" style="width:400px">
                    <el-option v-for="(item, index) in bankList" :key="item.bankCode" :label="item.bankName"
                        :value="item.bankCode"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开户行所在省份" prop="provinceCode">
                <el-select v-model="form.provinceCode" placeholder="请选择" @change="bankProvinceChange"
                    style="width:400px" :disabled="!form.bankCode">
                    <el-option v-for="(item, index) in provinceList" :key="item.provinceCode" :label="item.provinceName"
                        :value="item.provinceCode"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开户行所在地区" prop="areaCode">
                <el-select v-model="form.areaCode" placeholder="请选择" @change="bankAreaChange" style="width:400px"
                    :disabled="!form.provinceCode">
                    <el-option v-for="(item, index) in areaList" :key="item.areaCode" :label="item.areaName"
                        :value="item.areaCode"></el-option>
                </el-select>
            </el-form-item>
              <el-form-item label="开户行所在支行" prop="branchBankCode" v-if="form.provinceCode!=='000'">
                <el-select v-model="form.branchBankCode" filterable placeholder="请选择(可搜索)" @change="branchBankChange"
                    style="width:400px" :disabled="!form.areaCode" ref="branchBank">
                    <el-option v-for="(item, index) in bankBranchList" :key="item.branchCode" :label="item.branchName"
                        :value="item.branchCode"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开户行所在支行" v-else>
                <el-select v-model="form.branchBankCode" filterable placeholder="请选择(可搜索)" @change="branchBankChange"
                    style="width:400px" :disabled="!form.areaCode" ref="branchBank">
                    <el-option v-for="(item, index) in bankBranchList" :key="item.branchCode" :label="item.branchName"
                        :value="item.branchCode"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
    </div>

    <!-- <div class="a-line-t-e0 footerBox">
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
            <el-button class="a-ml-24 a-mt-15 " @click="cancelSubmit" style="margin-left:16px;">
                &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
        </div> -->

</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default: {}
        }

    },
    data() {
        var checkPhone = (rule, value, callback) => {
            let reg = RegExp('^[1][3,4,5,6,7,8,9][0-9]{9}$');
            if (reg.test(value)) {
                callback();
            } else {
                callback(new Error('手机号格式不正确'));
            }
        };
        var isNumAletter = (rule, value, callback) => {
            if (/^[0-9a-zA-Z]/.test(value)) {
                callback();
            } else {
                callback(new Error('输入格式不正确'));
            }
        };
        return {
            rules: {
                accountPersonNumber: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }, { validator: isNumAletter, trigger: 'blur' }],
                accountNumber: [{ required: true, message: '请输入企业对公账户', trigger: 'blur' }, { validator: isNumAletter, trigger: 'blur' }],
                accountName: [{ required: true, message: '请输入开户名', trigger: 'blur' }],//开户名
                isPrivateAccount: [{ required: true, message: '请选择账户类型', trigger: 'blur' }],
                bankCode: [{ required: true, message: '请选择开户行', trigger: 'change' }],
                provinceCode: [{ required: true, message: '请选择开户行所在省份', trigger: 'change' }],
                areaCode: [{ required: true, message: '请选择开户行所在地区', trigger: 'change' }],
                branchBankCode: [{ required: true, message: '请选择支行', trigger: 'change' }],
                mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { validator: checkPhone, trigger: 'blur' }],
                cardNo: [{ required: true, message: '请输入身份证号', trigger: 'blur' }, { validator: isNumAletter, trigger: 'blur' }],
            },
            bankList: [],//开户行列表
            provinceList: [],// 开户行所在省份
            areaList: [],// 开户行所在地区
            bankBranchList: [],//开户行支行
        }
    },
    mounted() {
        this.getBankList()
    },
    methods: {
        getBankList() {
            this.$Axios._post({
                url: this.$Config.apiUrl.getDictBankList,
                method: "post",
                params: {
                    "pageNum": 0,
                    "pageSize": 0,
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.bankList = res.result.data.list
                    // this.getDictBankProvince()
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 获取开户行所在省份
        getDictBankProvince() {
            if (!this.form.bankCode) {
                this.$message.error('请先选择开户行')
            }
            this.$Axios._post({
                url: this.$Config.apiUrl.getDictBankProvince,
                method: "post",
                params: {
                    bankCode: this.form.bankCode,
                    "pageNum": 0,
                    "pageSize": 0,
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.provinceList = res.result.data.list
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 获取开户行所在地区
        getDictBankArea() {

            let provinceName = this.provinceList.find(res => {
                return res.provinceCode == this.form.provinceCode
            }).provinceName
            this.$Axios._post({
                url: this.$Config.apiUrl.getDictBankArea,
                method: "post",
                params: {
                    bankCode: this.form.bankCode,
                    provinceName: provinceName,
                    provinceCode: this.form.provinceCode,
                    "pageNum": 0,
                    "pageSize": 0,
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.areaList = res.result.data.list
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 获取开户行支行
        getDictBankBranchList() {
            let provinceName = this.provinceList.find(res => {
                return res.provinceCode == this.form.provinceCode
            }).provinceName
            let areaName = this.areaList.find(res => {
                return res.areaCode == this.form.areaCode
            }).areaName
            this.$Axios._post({
                url: this.$Config.apiUrl.getDictBankBranchList,
                method: "post",
                params: {
                    bankCode: this.form.bankCode,
                    provinceName: provinceName,
                    provinceCode: this.form.provinceCode,
                    areaCode: this.form.areaCode,
                    areaName: areaName,
                    "pageNum": 0,
                    "pageSize": 0,
                }
            }).then(res => {
                if (res.result.code == 0) {
                    this.bankBranchList = res.result.data.list
                } else {
                    this.$message.error(res.result.message)
                }
            })
        },
        // 选择开户行
        bankChange(datas) {
            this.form.bankId = this.bankList.find(res => {
                return res.bankCode == datas
            }).id
            console.log()
            this.form.provinceCode = ''
            this.form.areaCode = ''
            this.form.areaCode = ''
            this.form.branchBankCode = ''
            this.provinceList = []// 开户行所在省份
            this.areaList = []// 开户行所在地区
            this.bankBranchList = []//开户行支行
            this.getDictBankProvince()
        },
        // 选择省份
        bankProvinceChange() {
            this.form.areaCode = ''
            this.form.areaCode = ''
            this.form.branchBankCode = ''
            this.areaList = []// 开户行所在地区
            this.bankBranchList = []//开户行支行
            if (!this.form.provinceCode) {
                this.$message.error('请先选择开户行所在省份')
            }
            this.getDictBankArea()
        },
        // 选择地区
        bankAreaChange() {
            this.form.branchBankCode = ''
            this.bankBranchList = []//开户行支行
            this.getDictBankBranchList()
        },
        branchBankChange(datas, b, c) {
            // this.$nextTick(()=>{
            //     this.$refs.branchBank.selectedLabel
            // })
        },
        submit() {

            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (!this.isRead) {
                        this.$confirm('请先同意《用户服务协议》和《隐私政策》。', '温馨提示', {
                            confirmButtonText: '确定',
                            showCancelButton: false,
                            type: 'warning'
                        }).then(_ => {

                        }).catch(_ => { })
                    } else {
                        this.bindBank()
                    }
                } else {
                    return false
                }
            })
        },
        cancelSubmit() {
            this.$confirm('是否确认取消?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(_ => {
                this.$router.back()
            }).catch(_ => { })
        },
        bindBank() {
            this.$confirm('是否确认提交?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (this.form.accountPersonNumber) {
                    this.form.accountNumber = this.form.accountPersonNumber
                    delete this.form.accountPersonNumber
                }
                this.$Axios._post({
                    url: this.$Config.apiUrl.bindBankAccount,
                    method: "post",
                    params: this.form
                }).then(res => {
                    if (res.result.code == 0) {
                        this.$router.replace({
                            path: '/businessCenter/business-center',
                            query: {
                                tabs: 'yhzh'
                            }
                        })
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            }).catch(() => {


            })
        },
    }
}
</script>

<style lang="scss" scoped>
.withdrawal-content {
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    font-size: 14px;
    padding: 0 25px;
}

.title {
    padding: 25px 0 17px
}

.footerBox {
    left: 255px;
    right: 43px;
    position: fixed;
    bottom: 0;
    background: #fff;
    margin-bottom: 20px;
    padding-bottom: 20px
}
</style>