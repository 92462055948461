<template>
    <div>
        <el-form ref="realnameForm" :model="realnameForm" :rules="rules" label-position="left" label-width="120px"
            :inline="true">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="管理员姓名" prop="adminRealName" size="large">
                        <el-input v-model="realnameForm.adminRealName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="管理员手机号" prop="adminMobile" size="large">
                        <el-input v-model="realnameForm.adminMobile"
                            @input="realnameForm.adminMobile = realnameForm.adminMobile.replace(/^\.+|[^\d]+/g, '')">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="a-mt-20">
                <el-col :span="12">
                    <el-form-item label="上传身份证正面" class="card0">
                        <idCardImg ref="pic" v-model="realnameForm.cardImgUrl">
                            <div slot="tip"></div>
                        </idCardImg>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="上传身份证反面" class="card1">
                        <idCardImg ref="pic" v-model="realnameForm.cardBackImgUrl">
                            <div slot="tip"></div>
                        </idCardImg>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="requirements a-mt-56">
                <div class="a-fs-16 a-fw-700">身份证拍摄要求：</div>
                <div class="a-c-normal a-fs-14 a-mt-16 a-flex-cfsfs">
                    <span>必须持有本人的有效二代身份证；</span>
                    <span>照片确保身份证边框完整，字体清晰，亮度均匀；</span>
                </div>
                <div class="a-flex-rfsc a-mt-16">
                    <div class="a-flex-ccc">
                        <img src="../../../assets/images/realname_1.png" alt="" />
                        <span class="a-fs-14 a-c-normal">标准</span>
                    </div>
                    <div class="a-flex-ccc a-ml-16">
                        <img src="../../../assets/images/realname_2.png" alt="" />
                        <span class="a-fs-14 a-c-normal">边框缺失</span>
                    </div>
                    <div class="a-flex-ccc a-ml-16">
                        <img src="../../../assets/images/realname_3.png" alt="" />
                        <span class="a-fs-14 a-c-normal">照片模糊</span>
                    </div>
                    <div class="a-flex-ccc a-ml-16">
                        <img src="../../../assets/images/realname_4.png" alt="" />
                        <span class="a-fs-14 a-c-normal">闪光强烈</span>
                    </div>
                </div>
            </div>
        </el-form>
        <le-service-privacy v-model="read" class="a-mt-56"></le-service-privacy>
        <!-- <div class="a-line-t-e0 footerBox">
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">下一步</el-button>
            <el-button class="a-ml-24 a-mt-15 " @click="cancelSubmit" style="margin-left:16px;">
                &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
        </div> -->
    </div>
</template>
<script>
import utils from "../../../utils/validate";
import idCardImg from "./le-upload-file-img-person.vue"
export default {
    components: { idCardImg },
    props: {
        realnameForm: {
            type: Object,
            default: {},
        },
        isRead: {
            type: Boolean,
        },
    },
    data() {
        var validatePhone = (rule, value, callback) => {
            if (!utils.isvalidPhone(value)) {
                callback(new Error("手机号格式不正确"));
            } else {
                callback();
            }
        };
        return {
            next: false,
            read: this.isRead,
            isReload: false,
            cardImgUrl: "",
            cardBackImgUrl: "",
            rules: {
                adminRealName: [
                    { required: true, message: "请输入管理员姓名", trigger: "blur" },
                    { min: 2, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                adminMobile: [
                    { required: true, message: "请输入管理员手机号", trigger: "blur" },
                    { validator: validatePhone, trigger: "blur" },
                ],
            },
        };
    },
    created() {
        if (this.realnameForm.cardImgUrl) {
            this.isReload = true;
            this.cardImgUrl = this.realnameForm.cardImgUrl;
        }
        if (this.realnameForm.cardBackImgUrl) {
            this.isReload = true;
            this.cardBackImgUrl = this.realnameForm.cardBackImgUrl;
        }
        this.realnameForm.cardImgUrl = "";
        this.realnameForm.cardBackImgUrl = "";
    },
    mounted() {
        if (this.isReload) {
            this.isReload = false;
            this.realnameForm.cardImgUrl = this.cardImgUrl;
            this.realnameForm.cardBackImgUrl = this.cardBackImgUrl;
        }
    },
    watch: {
        read(val) {
            this.$emit("update:isRead", val);
        },
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
.card0,
.card1 {
    /deep/ .el-form-item__label {
        margin-bottom: 15px;
        width: 100% !important;
    }

    /deep/ .el-form-item__content {
        >div {
            >div {
                position: relative;

                .el-upload-list--picture-card {
                    position: absolute;

                    .el-upload-list__item {
                        height: 218px;
                        width: 342px;
                        text-align: center;
                    }

                    .el-upload-list__item-thumbnail {
                        width: auto;
                        height: 100%;
                    }
                }
            }
        }
    }

    /deep/ .el-upload--picture-card {
        background-color: none;
        background: url(../../../assets/images/card0_bg.png) no-repeat center/100%;
        width: 342px;
        height: 218px;
        line-height: 218px;

        i {
            color: #007aff;
            font-size: 80px;
            vertical-align: middle;
        }
    }
}

.card1 {
    /deep/ .el-upload--picture-card {
        background: url(../../../assets/images/card1_bg.png) no-repeat center/100%;
    }

    margin-left: 12px;
}

.requirements {
    img {
        width: 120px;
        height: 88px;
        margin-bottom: 8px;
    }
}
</style>