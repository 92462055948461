<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main">
            <div class="addReal" v-if="addshow">
                <span class="a-fs-16 a-fw-700">{{ active == 1 ? '注册步骤' : '商户信息' }}</span>

                <div class="step-content">
                    <el-steps :active="active">
                        <el-step>
                            <div slot="icon" class="trade-step-icon ">
                                <span class="trade-step-icon-num a-flex-rcc">1</span>
                                <span class="trade-step-icon-text a-fs-16">管理员实名认证</span>
                            </div>
                        </el-step>
                        <el-step>
                            <div slot="icon" class="trade-step-icon ">
                                <span class="trade-step-icon-num a-flex-rcc">2</span>
                                <span class="trade-step-icon-text a-fs-16">填写商户信息</span>
                            </div>
                        </el-step>
                        <el-step>
                            <div slot="icon" class="trade-step-icon ">
                                <span class="trade-step-icon-num a-flex-rcc">3</span>
                                <span class="trade-step-icon-text a-fs-16">添加银行账户</span>
                            </div>
                        </el-step>

                    </el-steps>
                </div>
                <realnameVue ref="realname" v-if="active == 1" :realnameForm.sync="realnameForm" :isRead.sync="isRead">
                </realnameVue>
                <minformationVue ref="minformation" v-if="active == 2" :formationForm="minformationForm">
                </minformationVue>
                <addbankVue ref="addbank" v-if="active == 3" :form="addbankForm"></addbankVue>


            </div>
            <succeseVue v-else :id="id"></succeseVue>

            <div class="a-line-t-e0 footerBox">
                <el-button type="primary" class="a-ml-24 a-mt-15 myblue" @click="submitForm" v-if="active == 1">
                    立即实名</el-button>
                <el-button type="primary" class="a-ml-24 a-mt-15 myblue" @click="submitFormM" v-if="active == 2">
                    下一步</el-button>
                <el-button type="primary" class="a-ml-24 a-mt-15 myblue" @click="submitFormT" v-if="active == 3">
                    立即提交</el-button>
                <el-button type="primary" class="a-ml-24 a-mt-15 myblue" @click="submitFormP" v-if="active == 3">
                    跳过</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 16px" @click="cancel" v-if="active !== 1">
                    &nbsp;&nbsp;&nbsp;上一步&nbsp;&nbsp;&nbsp;
                </el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 16px" @click="goback">
                    &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;
                </el-button>

            </div>

        </el-card>



    </div>
</template>

<script>
import utils from '../../utils/index'
import realnameVue from './child/realname.vue';
import minformationVue from './child/minformation.vue';
import addbankVue from './child/addbank.vue';
import succeseVue from './child/succese.vue';

export default {
    components: { realnameVue, minformationVue, succeseVue, addbankVue },
    data() {

        return {
            active: 1,
            flag: 1,
            id: void 0,
            addshow: true,
            isRead: false,
            form: null,
            realnameForm: {
                adminRealName: '',
                adminMobile: '',//管理员手机号
                cardImgUrl: '',
                cardBackImgUrl: '',
            },
            minformationForm: {
                newType: 1,
                name: '',
                brand: '',
                certNumber: '',
                ycertNumber: '',
                provinceCode: '',
                cityCode: '',
                districtCode: '',
                repIdNumber: '',
                certImagePath: '',
                is3in1: 1,
                taxRegister: '',
                taxRegisterImagePath: '',
                repCardFrontImagePath: '',
                repCardBackImagePath: ''
            },
            addbankForm: {
                accountName: '',//开户名
                accountNumber: '',//银行账号
                accountPersonNumber: '',
                bankCode: '',
                provinceCode: '',
                areaCode: '',
                branchBankCode: '',//支行code
                bankBranchName: '',//支行名称
                bankId: '',//银行id（支行id）
                isPrivateAccount: 1
            }

        }
    },
    methods: {
        //提交实名认证
        submitForm() {
            this.realnameForm = this.$refs['realname'].realnameForm
            this.$refs['realname'].$refs['realnameForm'].validate((valid) => {
                if (valid) {
                    if (!this.realnameForm.cardImgUrl) {
                        this.$message.error('请上传身份证正面')
                        return false
                    }
                    if (!this.realnameForm.cardBackImgUrl) {
                        this.$message.error('请上传身份证背面')
                        return false
                    }
                    if (!this.isRead) {
                        this.$message.error('请先阅读协议')
                        return false
                    }
                    this.$Axios._post({
                        url: this.$Config.apiUrl.adminOcrForCompany,
                        params: this.realnameForm
                    }).then(res => {
                        if (res.result.code == 0) {
                            this.$notify({ title: '提交成功，请进行下一步', type: 'success' })
                            this.flag = 2
                            this.active = 2
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //填写商户信息
        submitFormM() {
            this.minformationForm = this.$refs['minformation'].formationForm

            this.$refs['minformation'].$refs['formationForm'].validate((valid) => {
                if (valid) {
                    if (!this.minformationForm.provinceCode) {
                        this.$message.error('请选择所在地区')
                        return false
                    }
                    if (!this.minformationForm.repCardFrontImagePath && this.minformationForm.newType == 1) {
                        this.$message.error('请上传法人身份证正面照')
                        return false
                    }
                    if (!this.minformationForm.repCardBackImagePath && this.minformationForm.newType == 1) {
                        this.$message.error('请上传法人身份证反面照')
                        return false
                    }
                    if (!this.minformationForm.certImagePath && this.minformationForm.newType == 1) {
                        this.$message.error('请选择上传营业执照照片')
                        return false
                    }
                    if (!this.minformationForm.is3in1 && !this.minformationForm.taxRegisterImagePath && this.minformationForm.newType == 1) {
                        this.$message.error('请选择上传税务登记照照片')
                        return false
                    }
                    if (this.minformationForm.ycertNumber && this.minformationForm.newType == 1) {
                        this.minformationForm.certNumber = this.minformationForm.ycertNumber
                    }
                    this.addbankForm.isPrivateAccount = this.minformationForm.newType == 1 ? 1 : 0

                    this.flag = 3
                    this.active = 3
                    // this.$set(this, 'addshow', false)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //注册银行  提交整个表单
        submitFormT() {
            let childVue = this.$refs['addbank']
            childVue.$refs['form'].validate((valid) => {
                if (valid) {
                    this.addbankForm = { ...this.addbankForm, 'bankBranchName': childVue.$refs['branchBank'].selectedLabel }
                    let aform = utils.copyData(this.addbankForm)
                    delete aform.provinceCode
                    this.form = { ...this.realnameForm, ...this.minformationForm, ...aform }
                    delete this.form.ycertNumber
                    delete this.form.provinceCode
                    delete this.form.areaCode
                    delete this.form.bankCode
                    delete this.form.bankId
                    this.$confirm('是否确认提交?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_ => {
                        this.$Axios._post({
                            url: this.$Config.apiUrl.substituteRegisterPc,
                            params: this.form
                        }).then(res => {
                            this.$route.meta.hash = "del";
                            if (res.result.code == 0) {
                                this.id = res.result.data
                                this.$notify({ title: '提交成功', type: 'succese' })

                                this.$set(this, 'addshow', false)
                            } else {
                                this.$message.error(res.result.message)
                            }
                        })
                    }).catch((error) => {
                        this.$message({
                            type: 'info',
                            message: '已取消提交'
                        });
                    })


                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 跳过添加银行卡这一步
        submitFormP () {
            this.form = { ...this.realnameForm, ...this.minformationForm }
            delete this.form.ycertNumber
            delete this.form.provinceCode
            delete this.form.areaCode
            delete this.form.bankCode
            delete this.form.bankId
            this.$confirm('是否确认提交?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(_ => {
                this.$Axios._post({
                    url: this.$Config.apiUrl.substituteRegisterPc,
                    params: this.form
                }).then(res => {
                    this.$route.meta.hash = "del";
                    if (res.result.code == 0) {
                        this.id = res.result.data
                        this.$notify({ title: '提交成功', type: 'succese' })

                        this.$set(this, 'addshow', false)
                    } else {
                        this.$message.error(res.result.message)
                    }
                })
            }).catch((error) => {
                this.$message({
                    type: 'info',
                    message: '已取消提交'
                });
            })
        },
        //取消
        cancel() {
            if (this.active == 1) {
                this.$route.meta.hash = "del";
                this.$router.replace({
                    path: '/customer/customer-list'
                })
            }
            if (this.active == 2) {
                if (this.flag != 3) {
                    this.$refs['minformation'].$refs['formationForm'].resetFields();
                    this.minformationForm = {
                        newType: 1,
                        name: '',
                        brand: '',
                        certNumber: '',
                        ycertNumber: '',
                        provinceCode: '',
                        cityCode: '',
                        districtCode: '',
                        repIdNumber: '',
                        certImagePath: '',
                        is3in1: 1,
                        taxRegister: '',
                        taxRegisterImagePath: '',
                        repCardFrontImagePath: '',
                        repCardBackImagePath: ''
                    }
                }

                this.active = 1
            }
            if (this.active == 3) {
                this.$refs['addbank'].$refs['form'].resetFields();
                this.active = 2
            }


        },
        //返回
        goback() {
            this.$route.meta.hash = "del";
            this.$router.replace({
                path: '/customer/customer-list'
            })
        }

    }
}
</script>
<style lang="scss" scoped>
.el-main {
    position: relative;
}

.addReal {
    padding: 4px;
    padding-bottom: 100px;
    // position: relative;

    .requirements {
        img {
            height: 88px;
            width: 120px;
        }
    }

}

.step-content {
    width: 90%;
    padding: 24px 0 57px;

    /deep/ .el-step__line {
        background-color: #fff;
    }


    /deep/ .trade-step-icon {
        padding: 0 6px !important;
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;

        .trade-step-icon-num {
            width: 22px;
            height: 22px;
            background: #007AFF;
            color: #FFFFFF;
            font-size: 10px;
            border-radius: 50%;
            margin-right: 6px
        }

        .trade-step-icon-text {
            font-size: 16px;
        }
    }


    /deep/ .el-step__icon.is-text {
        width: auto;
        border: none
    }




    /deep/ .is-process,
    /deep/ .is-wait {
        .trade-step-icon-text {
            color: #909499;
        }

        .trade-step-icon-num {
            background: #ADB0B3;
        }
    }

    /deep/ .is-finish {
        .trade-step-icon-text {
            color: #303133;
        }

        .el-step__line-inner {
            border: none;
        }

    }


    /deep/ .el-step__line-inner {
        border-width: 0px;
    }

}

.footerBox {
    // left: 254px;
    // right: 43px;
    // position: fixed;
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // background: #fff;
    // padding-bottom: 24px;
    // margin-bottom: 0;
}

/deep/ .el-step.is-horizontal .el-step__line {
    margin-right: 0px;
    width: 100%;
    height: 2px;
    background-color: none !important;
    background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
    background-size: 8px 1px;
    background-repeat: repeat-x;

}
</style>