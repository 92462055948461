import { render, staticRenderFns } from "./le-upload-file-img-person.vue?vue&type=template&id=01c84d65&scoped=true&"
import script from "./le-upload-file-img-person.vue?vue&type=script&lang=js&"
export * from "./le-upload-file-img-person.vue?vue&type=script&lang=js&"
import style0 from "./le-upload-file-img-person.vue?vue&type=style&index=0&id=01c84d65&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01c84d65",
  null
  
)

export default component.exports